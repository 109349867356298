import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <nav className="level has-background-black has-text-white-ter">
              <div className="level-item has-text-centered">
                <Link to="/" className="has-text-white-ter" >
                  Home
                </Link>
              </div>
              <div className="level-item has-text-centered">
                <Link className="menu-item has-text-white-ter" to="/about">
                  About
                </Link>
              </div>
              <div className="level-item has-text-centered">
                <a
                  className="menu-item has-text-white-ter"
                  href="/admin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Admin
                </a>
              </div>
              <div className="level-item has-text-centered">
                <Link className="menu-item has-text-white-ter" to="/blog">
                  Latest Stories
                </Link>
              </div>
              <div className="level-item has-text-centered">
                <Link className="menu-item has-text-white-ter" to="/contact">
                  Contact
                </Link>
              </div>
            </nav>
          </div>
          <div className="container">
            &nbsp;
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
